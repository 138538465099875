<template>
<v-row>
  <v-col cols="12" md="6">
    <search-box
        label="* Comprador"
        popup-label="Selecione um Comprador"
        :id.sync="value.idComprador"
        :nomeCompleto.sync="value.descricaoComprador"
        :hidden-clear="true"
        :value="comprador"
        :loader-fn="loadeComprador"
        item-key="id"
        item-text="nomeCompleto"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Comprador encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="6">
    <search-box
        label="* Item Demandado"
        popup-label="Selecione uma Demanda"
        :id.sync="value.idOferta"
        :descricao.sync="value.descricaoOferta"
        :hidden-clear="true"
        :value="oferta"
        :loader-fn="loadeOferta"
        item-key="id"
        item-text="descricao"
        :rules="[
            this.$validators.notNullOrUndefined
        ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Oferta encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" md="12">
    <v-textarea 
      dense
      rows="6"
      label="* Descrição da Demanda"
      :rules="[
          this.$validators.string.required,
          v => this.$validators.string.greaterThanOrEquals(v, 1),
          v => this.$validators.string.lessThanOrEquals(v, 4000),
      ]"
      v-model.trim="value.descricao"/>
  </v-col>
  <v-col cols="12" md="6">
    <v-text-field label="Data Expiração"
                  dense
                  type="date"
                  :rules="[
                      this.$validators.string.required,
                  ]"
                  v-model="value.dataExpiracao" />
  </v-col>
  <v-col cols="12" md="6">
    <lookup-dominio label="* Status"
                    :hidden-clear="false"
                    hint="Selecione o Status"
                    v-model="value.status"
                    :rules="[
                        this.$validators.string.required,
                    ]"
                    :type="lookups.status"
                    />
  </v-col>
    <v-col cols="12"> 
    <search-box-multiple
        label="* Regiões"
        class="search-box-cursos"
        popup-label="Selecione as Regiões"
        v-model="value.regioes"
        :loader-fn="loaderRegioes"
        item-key="key"
        item-text="valor"
        :rules="[
              this.$validators.notNullOrUndefined,
          ]">
      <template v-slot:selection="{item}" >
        <div @click.stop  class="curso-chip v-chip">
          <div class="item-description">{{item.valor}}</div>
          <div class="remove-button" @click="removeRegiao(item)">
            <v-btn x-small icon class="ma-0 pa-0">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma Região encontrada
        </p>
      </template>
    </search-box-multiple>
  </v-col> 
</v-row>
</template>

<script>
import {ItemDeDominio, findFormItemDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import SearchBoxMultiple from "../common/SearchBoxMultiple";
//PKs
import {findAll as findAllComprador} from "../../../api/comprador";
import {findAll as findAllOferta} from "../../../api/oferta";

export default {
  name: "DemandaForm",
  components: {SearchBox, LookupDominio, SearchBoxMultiple},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO,
      },
      loading: false,
      error: null,
    }
  },
  computed: {
    comprador(){
      if (this.value.idComprador == null) return null;
      return {
        id: this.value.idComprador,
        nomeCompleto: this.value.descricaoComprador
      }      
    },
    oferta(){
      if (this.value.idOferta == null) return null;
      return {
        id: this.value.idOferta,
        descricao: this.value.descricaoOferta
      }      
    },
  },
  watch: {
  },
  methods: {
    removeRegiao(item) {
      this.value.regioes = this.value.regioes.filter(v => v.key !== item.key);
    },   
    loadeComprador(page, search) {
      return findAllComprador(page, {
        nome: search
      })
    },
    loadeOferta(page, search) {
      return findAllOferta(page, {
        descricao: search
      })
    },
    loaderRegioes(page, search){
      const niveis = findFormItemDominio(1,ItemDeDominio.REGIOES_BRASIL);
      return niveis;
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.curso-chip {
  padding-right: 0;
  cursor: initial;
  background-color: rgba(0,0,0,0.1);
  height: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;

  .item-description {
    min-width: 150px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
  }
  .remove-button {
    height: 32px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    border-left: 2px solid #dedede;
    padding: 5px 8px !important;
    transition: background-color 0.5s ease 0s;
    &:hover {
      background-color: rgba(0,0,0,0.3);
    }

  }

}
</style>